/*global chrome*/

import React, { FC, useContext, useEffect } from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  Center,
  DarkMode,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  Image,
  useBreakpointValue,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import NextLink from "next/link";

import { Logo } from "../components/Logo";
import { SignInForm } from "../views/SignInForm";
import { UserContext } from "../context/User";
import { sendTokenToChromeExtension } from "../lib/extension";

const calbackUri = encodeURIComponent(process.env.DISCORD_CALLBACK);
const discordClientId = process.env.DISCORD_CLIENT_ID;
const scope = `identify+guilds+email+applications.commands.permissions.update`;
const discordUrl = `https://discord.com/api/oauth2/authorize?client_id=${discordClientId}&redirect_uri=${calbackUri}&response_type=code&scope=${scope}`;

export const Auth: FC = () => {
  const { user, userLoaded } = useContext(UserContext);
  const router = useRouter();

  useEffect(() => {
    if (userLoaded && user) {
      const {jwt} = user;
      sendTokenToChromeExtension({ 
        extensionId: "gcbejbiapllmialiecbkfgiadgeblbdo",
        jwt
      })
      router.push("/app/settings");
    }
  });

  return (
    <Flex
      minH={{ base: "auto", md: "100vh" }}
      bgGradient={useBreakpointValue({
        md: mode(
          "linear(to-r, blue.600 50%, white 50%)",
          "linear(to-r, blue.600 50%, gray.900 50%)"
        ),
      })}
    >
      <Flex maxW="8xl" mx="auto" width="full">
        <Box flex="1" display={{ base: "none", md: "block" }}>
          <DarkMode>
            <Flex
              direction="column"
              px={{ base: "4", md: "8" }}
              height="full"
              color="on-accent"
            >
              <Flex align="center" h="32">
                <NextLink href="/">
                  <Image src="/NFTee_white_full.png" width={180} marginTop={24} />
                </NextLink>
              </Flex>
              <Flex flex="1" align="center">
                <Stack spacing="8">
                  <Stack spacing="6">
                    <Heading size={useBreakpointValue({ md: "lg", xl: "lg" })}>
                      <Text
                        color="blue.900"
                        fontSize={useBreakpointValue({
                          md: "60px",
                          xl: "80px",
                        })}
                        fontWeight="bold"
                      >
                        NFTee Labs
                      </Text>
                      Solana tools and services
                    </Heading>
                    <Text fontSize="lg" maxW="md" fontWeight="medium">
                      Create an account and discover usefull tools for you and
                      your community.
                    </Text>
                  </Stack>
                  <HStack spacing="4">
                    <AvatarGroup
                      size="md"
                      max={useBreakpointValue({ base: 2, lg: 5 })}
                      borderColor="on-accent"
                    >
                      <Avatar
                        name="León / 🦁💻.sol"
                        src="https://pbs.twimg.com/profile_images/1516917421663264768/DYZfn57b_400x400.jpg"
                      />
                      <Avatar
                        name="User 5"
                        src="https://nqfyqo6ss4q2cekf3qfexsb3ysopqwa4krer26yy6hrlrwdgda.arweave.net/bAuIO9KXIaERRdwKS8g7xJz4_WBxUSR17GPHiuNhmGI"
                      />
                      <Avatar
                        name="User 4"
                        src="https://reshcehldpz2fhq6w7vtf566lxubmfrmgvy4vpkwqgr46om2.arweave.net/iSRxEOsb86KeHr__frMvfeXegWFiw1ccq9VoGjzzmaM?ext=png"
                      />
                      <Avatar
                        name="User 3"
                        src="https://pbs.twimg.com/media/FN_Ic3eXwAQD-mC?format=png&name=small"
                      />
                      <Avatar
                        name="User 2"
                        src="https://pbs.twimg.com/media/FOK2zj0WYAURm2i?format=jpg&name=small"
                      />
                    </AvatarGroup>
                    <Text fontWeight="medium">Join 10.000+ users</Text>
                  </HStack>
                </Stack>
              </Flex>
              <Flex align="center" h="24">
                <Text color="on-accent-subtle" fontSize="sm">
                  © 2022 NFTee Labs. All rights reserved.
                </Text>
              </Flex>
            </Flex>
          </DarkMode>
        </Box>
        <Center flex="1">
          <SignInForm
            px={{ base: "4", md: "8" }}
            py={{ base: "12", md: "48" }}
            width="full"
            maxW="md"
            discordUrl={discordUrl}
          />
        </Center>
      </Flex>
    </Flex>
  );
};

export default Auth;
