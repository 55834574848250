import React, { FC } from "react";
import {
  Button,
  Heading,
  HStack,
  Stack,
  StackProps,
  Text,
  Icon,
  Image,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";

import { FaDiscord } from "react-icons/fa";
import NextLink from "next/link";

type SignInForm = StackProps & {
  discordUrl: string;
};

export const SignInForm: FC<SignInForm> = (props: SignInForm) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { discordUrl, ...htmlProps } = props;

  return (
    <VStack spacing="8" {...htmlProps}>
      <Stack spacing="6">
        {isMobile && (
          <Image src="/NFTee_white_full.png" width={180} margin="0 auto" />
        )}
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
            Log in to your account
          </Heading>
          <HStack spacing="1" justify="center">
            <Text color="muted">Don't have an account? Sign up</Text>
          </HStack>
        </Stack>
      </Stack>
      <Stack spacing="6">
        <Stack spacing="4">
          <NextLink href={discordUrl}>
            <Button
              variant="primary"
              leftIcon={<Icon as={FaDiscord} boxSize="5" />}
              iconSpacing="3"
            >
              Sign in with Discord
            </Button>
          </NextLink>
        </Stack>
      </Stack>
    </VStack>
  );
};
