import { browser } from 'webextension-polyfill-ts';

export const sendTokenToChromeExtension = ({ extensionId, jwt }) => {
  window.postMessage({
    from: "web",
    type: "auth",
    data : {
      jwt
    }
  }, "*");

  //@ts-ignore
  // browser.runtime.sendMessage("comkmphbheahfollkgkoofmaecmojpnj", { jwt }, (response) => {
  //   console.log(response)
  //   if (!response.success) {
  //     console.log("error sending message", response);
  //     return response;
  //   }
  //   console.log("Sucesss ::: ", response.message);
  // });

};
